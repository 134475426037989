/**
 * Simple menu class
 */
class Menu {
  menu = null;
  toggleBtns = null;

  constructor() {
    this.menu = document.getElementsByClassName('js-menu')[0];
    this.toggleBtns = document.getElementsByClassName('js-menu-toggle');

    for (let btn of this.toggleBtns) {
      btn.addEventListener('click', this.toggleMenu.bind(this));
    }
  }

  toggleMenu() {
    $(this.menu).toggleClass('js-opened');
  }
}